
    import { useRouter } from 'vue-router';
    import { defineComponent } from 'vue';
    import { IonButton } from '@ionic/vue';
    import axios, { AxiosResponse } from 'axios';
    import { useZenShipStore } from '@/stores/zenship';
    import { IonIcon } from '@ionic/vue';
    import { personCircleOutline, lockClosedOutline } from 'ionicons/icons';
    import {
    kList,
    kBlockTitle,
    kListInput,
  } from 'konsta/vue';
    export default defineComponent({
      name: "LoginCard",  
      components: { kList,kListInput , kBlockTitle, IonButton, IonIcon },
      setup() {
        const store = useZenShipStore();
        //cannot destructure store like const { name, doubleCount } = store
        //const { user } = storeToRefs(store)
        // the increment action can just be extracted then return as variable
        const { setToken } = store;
        const version = process.env.VUE_APP_VERSION as string;

        const router = useRouter();
        return {
          store,
          router,          
          setToken,
          personCircleOutline,
          lockClosedOutline,
          version
        }
      },
      data() {
         return {
            username : "",
            password : "",
            usernameError: "",
            passwordError: "",
            validatedOnce: false,
            alertOpen : false,
            alertMessage : ""
         }
      },
      mounted() {         
        //this.store.logOut();
      },
      computed: {
         showUsernNameError() {
            if(this.validatedOnce === false) return "";
            if(this.username.length === 0) {
              return " ";
            }
            return "";
         },
         showPasswordError() {
          if(this.validatedOnce === false) return "";
          if(this.password.length === 0 ) {
            return " ";
          }
          return "";
         }
      },
      methods: {
         async loginAction() {
            this.setLoading(true);
            try {
              await axios.post(
              process.env.VUE_APP_API_URL + '/zenship/token',
                  {
                    email: this.username,
                    password: this.password,
                    device_name: this.getDeviceName()
                  }
              ).catch((error) => {
                if(error.message) {
                  this.store.consoleLog(error.response.data.message);
                  this.store.setAlert(true,error.response.data.message);
                  //debugger;
                  //this.store.alertOpen = true;
                  //this.alertMessage = error.response.data.message;
                  //this.alertOpen = true;
                }
                this.setLoading(false);
              })
              .then((response) => {
                  if(response) {
                    let data = (response as AxiosResponse).data;
                    this.store.setToken(data);
                    this.router.push('/home')
                  }  
                  this.setLoading(false);
              });
            }
            catch (error) {
              this.store.consoleLog(error);
              this.setLoading(false);            
            }
         },
         getDeviceName() {
           let device = "Unknown";
           let checks = ["iPhone","Android","Window","Apple"];
           checks.every(function(value) {
             if(navigator.userAgent.indexOf(value)) {
                device = value;
                return false;
             }
           });
           return device;
         },
         clearUserName() {
           this.username = "";
         },
         clearPassWord() {
          this.password = "";
         },
         onUserNameChanged(e : any) {
            this.username = e.target.value;
         },
         onEnter(e: any) {
            return this.checkForm(e);
         },
         onPasswordNameChanged(e: any) {
            this.password = e.target.value;
         },
         checkForm(e:any) {         
            this.validatedOnce = true;                       
            if(!this.username) {
               return false;
            }
            if(!this.password) {
              return false;
            }
            e.preventDefault();
            this.loginAction();
         },
         setLoading(val : boolean) {
           this.store.setLoading(val);
        }, 
      }      
    });
  