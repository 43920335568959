import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_zen_field = _resolveComponent("zen-field")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, {
    inset: _ctx.inset,
    lines: "full"
  }, {
    default: _withCtx(() => [
      (_ctx.title.length > 0)
        ? (_openBlock(), _createBlock(_component_ion_list_header, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getList, (field) => {
        return (_openBlock(), _createBlock(_component_zen_field, {
          key: field.label,
          field: field
        }, null, 8, ["field"]))
      }), 128)),
      _createVNode(_component_ion_button, {
        expand: "full",
        class: "ion-margin-top",
        color: "success",
        onClick: _ctx.close
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["inset"]))
}