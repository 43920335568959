import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f76f9e90"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!

  return (_ctx.field.componentName === 'header')
    ? (_openBlock(), _createBlock(_component_ion_list_header, {
        key: 0,
        class: _normalizeClass(_ctx.field.getClassNames())
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, {
            style: {"margin-top":"10px"},
            class: _normalizeClass(_ctx.field.getLabelClassNames())
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.field.getLabel()), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }, 8, ["class"]))
    : (_ctx.field.componentName == 'qr')
      ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              style: {"margin-left":"auto","margin-right":"auto","padding":"20px"},
              src: _ctx.field.getValue()
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
          default: _withCtx(() => [
            (_ctx.field.hasStartIcon())
              ? (_openBlock(), _createBlock(_component_ion_icon, {
                  key: 0,
                  slot: "start",
                  icon: _ctx.getIcon(_ctx.field.getStartIcon()),
                  color: _ctx.getIconColor(_ctx.field.getStartIcon())
                }, null, 8, ["icon", "color"]))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.field.getLabel()), 1),
                (_ctx.field.hasNote())
                  ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.field.getNote()), 1))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.getTags(), (tag, tagIndex) => {
                  return (_openBlock(), _createBlock(_component_ion_badge, {
                    key: tagIndex,
                    class: "black"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(tag), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            (_ctx.field.componentName === 'text')
              ? (_openBlock(), _createBlock(_component_ion_input, {
                  key: 1,
                  readonly: _ctx.field.getReadOnly(),
                  class: "ion-text-right text",
                  value: _ctx.field.getValue()
                }, null, 8, ["readonly", "value"]))
              : _createCommentVNode("", true),
            (_ctx.field.componentName === 'textArea')
              ? (_openBlock(), _createBlock(_component_ion_textarea, {
                  key: 2,
                  readonly: _ctx.field.getReadOnly(),
                  class: "ion-text-left text",
                  value: _ctx.field.getValue()
                }, null, 8, ["readonly", "value"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
}