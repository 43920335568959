
        import {
          //IonItem,
          IonLabel,
          IonButton,
          //IonIcon,
          //IonContent,
          //IonInput,
          IonList,
          IonListHeader,
        } from '@ionic/vue';
        import { defineComponent } from 'vue';
        import ZenField from './ZenField.vue';
        import { useZenShipStore } from '@/stores/zenship';
        import { checkmarkCircleOutline, closeCircleOutline, stopCircleOutline, bookmark } from 'ionicons/icons';

      
        export default defineComponent({
          name: 'ZenForm',      
          components: { 
                        //IonItem, 
                        IonList,
                        IonLabel,
                        IonButton,
                        //IonContent,
                        //IonInput,
                        //IonIcon,
                        IonListHeader,                       
                        ZenField,                     
                      },
          props: {
            inset : {
              type: Boolean,
              default: false
            },
            title: {
              type: String,
              default : ""  
            },
            items: {
              type: Object as any, 
              default: null
            }
          },           
          setup() {            
            const store = useZenShipStore();            
            return {             
                store,
                checkmarkCircleOutline,
                closeCircleOutline,
                bookmark,
                stopCircleOutline         
            };            
          },
          methods: {
            getIcon(icon = ["default","primary"]) {              
              switch(icon[0]) {
                  case 'checkmarkCircleOutline': 
                    return checkmarkCircleOutline;
                  case 'closeCircleOutline':
                    return closeCircleOutline;  
                  case 'stopCircleOutline':
                    return stopCircleOutline;
                  case 'bookmark':
                    return bookmark;                        
                  case 'default':
                    return '';  
              }
              return '';
            },
            close() {
                this.store.closeModal();
            },
            getIconColor(icon = ["default","primary"]) {                 
              return icon[1];
            },
          },
          computed: {
            getList() {   
                //console.log(this.store.currentModal.getForm().getFields());      
                return this.store.currentModal.getForm().getFields();
              }
           },
        });
      