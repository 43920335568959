
    import {
      IonContent,
      IonHeader,
      IonTitle,
      IonToolbar,
      IonButtons,
      IonButton,
      modalController,
    } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { useZenShipStore } from '@/stores/zenship';
    import ZenForm from './ZenForm.vue';
    import { onMounted,onUnmounted } from 'vue'
  
    export default defineComponent({
      name: 'CustomModal',      
      components: { IonContent, 
                    IonHeader, 
                    IonTitle, 
                    IonToolbar, 
                    IonButtons, 
                    IonButton, 
                    ZenForm  
                  },
      setup() {
        const store = useZenShipStore();
        onMounted(()=> {
           store.currentModal.onMounted();
        });
        onUnmounted(()=> {
          store.currentModal.onUnmounted();
        });
        return {             
            store,            
        };
    },            
      methods: {        
        cancel() {
          //this.store.currentResource.refreshDetailModal();
          return modalController.dismiss(null, 'cancel');
        },
        handlePrimaryAction() {
         const currentForm = this.store.currentModal.getForm(); 
         //const showConfirmation = currentForm.showConfirmation();
         const showConfirmation = false;   
         const handlePrimaryActionForm = currentForm.handlePrimaryAction.bind(currentForm);
         if(showConfirmation) {           
           this.store.presentConfirmation(currentForm.getConfirmationText(), handlePrimaryActionForm);
         }
         else {
            handlePrimaryActionForm();
         }
        },
      },
    });
  