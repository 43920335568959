import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_block_title = _resolveComponent("k-block-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_k_list_input = _resolveComponent("k-list-input")!
  const _component_k_list = _resolveComponent("k-list")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_k_block_title, {
      large: "",
      class: "logo"
    }, {
      default: _withCtx(() => [
        _createTextVNode("Zenship")
      ]),
      _: 1
    }),
    _createVNode(_component_k_list, {
      "inset-ios": "",
      "strong-ios": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_k_list_input, {
          ref: "username",
          outline: "",
          label: "Username",
          "floating-label": "",
          type: "username",
          onClear: _ctx.clearUserName,
          value: _ctx.username,
          required: true,
          onInput: _ctx.onUserNameChanged,
          error: _ctx.showUsernNameError,
          "clear-button": _ctx.username.length > 0,
          onKeyup: _withKeys(_ctx.onEnter, ["enter"]),
          placeholder: "Your username"
        }, {
          media: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              size: "large",
              icon: _ctx.personCircleOutline
            }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["onClear", "value", "onInput", "error", "clear-button", "onKeyup"]),
        _createVNode(_component_k_list_input, {
          ref: "password",
          outline: "",
          label: "Password",
          "floating-label": "",
          value: _ctx.password,
          autocomplete: "off",
          required: true,
          onClear: _ctx.clearPassWord,
          error: _ctx.showPasswordError,
          onInput: _ctx.onPasswordNameChanged,
          type: "password",
          "clear-button": _ctx.password.length > 0,
          onKeyup: _withKeys(_ctx.onEnter, ["enter"]),
          placeholder: "Your password"
        }, {
          media: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              size: "large",
              icon: _ctx.lockClosedOutline
            }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["value", "onClear", "error", "onInput", "clear-button", "onKeyup"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_button, { onClick: _ctx.checkForm }, {
      default: _withCtx(() => [
        _createTextVNode("Sign In")
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}