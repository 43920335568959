import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_k_dialog_button = _resolveComponent("k-dialog-button")!
  const _component_k_dialog = _resolveComponent("k-dialog")!
  const _component_ion_app = _resolveComponent("ion-app")!
  const _component_k_provider = _resolveComponent("k-provider")!

  return (_openBlock(), _createBlock(_component_k_provider, { theme: "ios" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_app, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_loading, {
            "is-open": _ctx.getLoading,
            cssClass: "my-custom-class",
            message: _ctx.getLoadingText,
            duration: 60000
          }, null, 8, ["is-open", "message"]),
          _createVNode(_component_k_dialog, { opened: _ctx.getAlertOpen }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getAlertTitle), 1)
            ]),
            buttons: _withCtx(() => [
              _createVNode(_component_k_dialog_button, {
                onClick: _cache[0] || (_cache[0] = () => { _ctx.store.handleAlertClose() })
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.store.alertCloseText), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.getAlertText) + " ", 1)
            ]),
            _: 1
          }, 8, ["opened"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}