
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { albumsOutline, home, mailUnread, bagOutline, search, readerOutline, menuOutline } from 'ionicons/icons';
import { useZenShipStore } from '@/stores/zenship';
import { Resource } from '@/lib/resource';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {    
    const store = useZenShipStore();
    return {
      home, 
      albumsOutline, 
      mailUnread,
      bagOutline,
      search,
      store,
      readerOutline,
      menuOutline
    }
  },
  watch:{
     $route (to, from){
      //console.log(`to: ${to.name} to ${from.name}`);
      if(to.name === 'inventory') {
         this.store.fetchShipItemMetrics();
      }  
      if(to.name === 'homeTab') {
         this.store.fetchFulfillmentMetrics();
      }  
      /**           
      if(from.name=='resourcePage') {
         window.setTimeout(() => this.store.setPreviousResource(this.store.currentResource),200);
      }
      **/
      if(to.name === 'resourcePage') {
        if(this.$route && this.$route.params.resource_name) {
            const name = this.$route.params.resource_name as any;
            const resource = (this.store.currentApp.resourceCollection[name] as Resource);
            this.store.setCurrentResource(resource);
            this.store.currentResource.defaultView = this.$route.params.view as string;
            this.store.currentResource.defaultViewFilter = this.$route.params.view_filter;
            this.store.currentResource.clearItems();            
            this.store.currentResource.fetchItems();              
        }
      }
      if(from.name==='qr') {
         this.store.clearPrintQR();
      }
     }
    } 
});
