
  import { defineComponent } from 'vue';
  import LoginCard from './LoginCard.vue';
  
  export default defineComponent({
    name: 'LoginContainer',
    components: { LoginCard },
    props: {
      name: String
    }
  });
  