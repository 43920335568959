
  import { defineComponent } from 'vue';
  import { IonPage } from '@ionic/vue';
  import LoginContainer from '@/components/LoginContainer.vue';

  export default defineComponent({
    name: 'LoginPage',
    components: { IonPage , LoginContainer },
    setup() {
      return {
       
      }
    }
  });
  