import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import LoginPage from '../views/LoginPage.vue';
import { useZenShipStore } from '@/stores/zenship';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/',
    name: 'root',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/tabs/shipments'
  },
  {
    path: '/product/',
    name: 'product',
    component: () => import('@/views/ProductPage.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        name: 'tabsIndex',
        redirect: '/tabs/home'
      },
      {
        path: 'home',
        name: 'tabsHome',
        component: () => import('@/views/HomePage.vue')
      },
      {
        path: 'shipments',
        name: 'shipments',
        component: () => import('@/views/ShipmentPage.vue')
      },
      {
        path: 'inventory',
        name: 'inventory',
        component: () => import('@/views/InventoryPage.vue')
      },
      {
        path: 'qr',
        name: 'qr',
        component: () => import('@/views/PrintQRPage.vue')
      },
      {
        path: 'resources/:resource_name?view=:view&view_filter=:view_filter&resouce_label=:resource_label',
        name: 'resourcePage',
        component: () => import('@/views/ResourcePage.vue'),
      },      
      {
        path: 'actions',
        name: 'actionIndex',
        component: () => import('@/views/BatchActionListPage.vue')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'register',
          pageTitle: 'Register',
          storeName: 'register',
          fields: ['upc','sku','prefix','status_list','location']
        }
      },
      {
        path: 'identify',
        name: 'identify',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'identify',
          pageTitle: 'Search',
          storeName: 'identified',
          fields: ['upc','sku','prefix','qr']
        }
      },
      {
        path: 'set-location',
        name: 'set-location',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'location',
          pageTitle: 'Location',
          storeName: 'location',          
          fields: ['location']
        }
      },
      {
        path: 'set-picked',
        name: 'set-picked',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'picked',
          pageTitle: 'Picked',
          storeName: 'picked',
          statusName: 'Picked',
          fields: ['status']
        }
      },
      {
        path: 'set-shipped',
        name: 'set-shipped',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'shipped',
          pageTitle: 'Shipped',
          storeName: 'shipped',
          statusName: 'Shipped',
          fields: ['status']
        }
      },
      {
        path: 'set-sku',
        name: 'set-sku',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'changeSku',
          pageTitle: 'Change SKU',
          storeName: 'change_sku',         
          fields: ['upc','sku','prefix']
        }
      },          
      {
        path: 'set-restock',
        name: 'set-restock',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'restock',
          pageTitle: 'Restock',
          storeName: 'restock',
          statusName: 'In Stock',
          fields: ['status']
        }
      },     
      {
        path: 'set-broken',
        name: 'set-broken',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'broken',
          pageTitle: 'Broken',
          storeName: 'broken',
          statusName: 'Broken',
          fields: ['status']
        }
      },
      {
        path: 'set-trashed',
        name: 'set-trashed',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'trashed',
          pageTitle: 'Trashed',
          storeName: 'trashed',
          statusName: 'Trashed',
          fields: ['status']
        }
      },        
      {
        path: 'set-on-hold',
        name: 'set-on-hold',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'onHold',
          pageTitle: 'On Hold',
          storeName: 'onHold',
          statusName: 'On Hold',
          fields: ['status']
        }
      },        
      {
        path: 'return-supplier',
        name: 'return-supplier',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'returnSupplier',
          pageTitle: 'Return to supplier',
          storeName: 'returnSupplier',
          statusName: 'Return to Supplier',
          fields: ['status']
        }
      },   
      {
        path: 'set-lost',
        name: 'set-lost',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'lost',
          pageTitle: 'Lost',
          storeName: 'lost',
          statusName: 'Lost',
          fields: ['status']
        }
      },           
      {
        path: 'shipped-supplier',
        name: 'shipped-supplier',
        component: () => import('@/views/ActionPage.vue'),
        props: {
          actionName: 'shippedSupplier',
          pageTitle: 'Shipped to supplier',
          storeName: 'shippedSupplier',
          statusName: 'Shipped to Supplier',
          fields: ['status']
        }
      },           
      {
        path: 'ship',
        name: 'ship',
        component: () => import('@/views/ShipPage.vue')
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/SettingsPage.vue')
      },
      {
        path: 'more',
        name: 'more',
        component: () => import('@/views/MorePage.vue')
      },      
      {
        path: 'search',
        name: 'search',
        component: () => import('@/views/SearchPage.vue')
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('@/views/ReportsPage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  const store = useZenShipStore();
  const { getLoggedIn } = store; 
  // canUserAccess() returns `true` or `false`
  const canAccess = getLoggedIn;
  if (!canAccess) {
    if(to.fullPath.indexOf('/login') < 0 ) {
      return '/login';
    }      
  }  
})

router.afterEach((to, from) => {
  const store = useZenShipStore();
  store.setCurrentRoute(to.name);
  store.clearAllScanCode();
  store.clearAllLockedFields();
});

export default router
