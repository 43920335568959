import { defineStore } from 'pinia'
import axios, { AxiosInstance } from 'axios';
import ActionResponse from '../types/ActionResponse';
import ActionHandler from '../types/ActionHandler';
import { v4 as uuidv4 } from 'uuid';
import {  ActionSheetButton, actionSheetController, modalController, alertController } from '@ionic/vue';
import { App } from '@/lib/app';
import { Resource } from '@/lib/resource';
import { toastController } from '@ionic/vue';
import CustomModal from '@/components/ZenModal.vue';
import { Modal } from '@/lib/modals/modal';

export const useZenShipStore = defineStore('zenship', {
    state: () => (
      { 
        user : {
           authenticated : false,
           name : '',
           token: '',           
           distribution_centers: [],
        },
        currentApp : null as any | App,
        currentResource: null as any | Resource,
        currentModal: null as any | Modal,
        debugAPI: false,
        environment: 'production',
        distribution_center_id : 0,
        axios: getAxios(),
        isLoading: false,
        loadingText: "Please wait ...",
        alertOpen : false,
        alertText : "",
        alertTitle : "Exception",
        alertCloseHandler: null,
        alertCloseText: 'Cancel',
        
        register : [] as ActionResponse[],
        identified: [] as ActionResponse[],
        picked : [] as ActionResponse[],        
        location: [] as ActionResponse[],
        shipped : [] as ActionResponse[],
        shippedSupplier : [] as ActionResponse[],
        returnSupplier : [] as ActionResponse[],        
        restock: [] as ActionResponse[],
        broken: [] as ActionResponse[],
        trashed: [] as ActionResponse[],
        change_sku: [] as ActionResponse[],
        onHold: [] as ActionResponse[],
        lost: [] as ActionResponse[],
        
        scanCode: '',
        qrCode: '',
        upcCode: '',
        skuCode: '',
        locationCode: '',
        status_list : 'In Stock',
        scannerEventHandler: null,
        enableScan: false,
        currentRoute: '',
        actionHandlers: [] as ActionHandler[],
        
        shipItemMetrics: { } as any,
        shipItemMetricRefreshedAt : 0,
        shipItemMetricsView: 'todayEvents',
        isLoadingShipItemMetrics : true,


        FFMetrics: { } as any,
        FFMetricRefreshedAt : 0,
        FFMetricsView: 'all',
        isLoadingFFMetrics : true,
        
        lockedFields: [] as string[],
        printQRCode: "",
        printQRLoading: false,
        printQREncoded: "" as any,

        prefixes : [],
        prefix: "",

      
        noTrailingSpace: true,
        enableUPC: false,
        enableWildCard: false,
        enableLogging: false,
        enableDepoFormat: false,
        console: [] as any
      }
    ),
    getters: {
      getToken : (state) => state.user.token,
      getLoading : (state) => state.isLoading,
      getLoadingText: (state) => state.loadingText,
      getAlertText: (state) => state.alertText,
      getAlertOpen: (state) => state.alertOpen,
      getAlertTitle: (state) => state.alertTitle,
      getShipItemMetrics:(state) => state.shipItemMetrics,
      getLoggedIn: (state) => {
        if(state.user.authenticated === false || state.user.token.length === 0) return false;
        return true;
      }      
    },
    actions: {
      init(env : string) {
         //console.log('store initialize');
         this.addScannerEventHandler();
         this.setEnvironment(env);
      },
      consoleLog(val: any) {
        if(this.environment === 'local') {
          if(console) console.log(val);
        }
        if(this.enableLogging === false) return;
        this.console.push(val);
      },
      destroy() {
        this.removeScannerEventHandler();
      },     
      clearPrintQR() {
        this.printQRCode = "";
        this.printQREncoded = "";
      },
      clearQREncoded() {
        this.printQREncoded = "";
      },
      async presentToast(message : string, position: 'top' | 'middle' | 'bottom', color: 'dark' | 'success' | 'danger') {
        const toast = await toastController.create({
          message: message,
          duration: 3000,
          position: position,
          color: color,
          buttons : [
            {
              text: 'Dismiss',
              role: 'cancel',                      
            }
          ]
        });
        await toast.present();
      },
      async getQR() {
        let currentQREncoded = "" as any;
        this.isLoading = true;
        let currentAlertOpen = false;
        let currentAlertText = "";
        await this.axios.post(
          process.env.VUE_APP_API_URL + '/zenship/qr/print',
          { 
            qr : this.printQRCode,            
          })
          .then(function(response) {        
            if(response.data) {
               if(response.data.code) {
                currentAlertOpen = true;
                currentAlertText = response.data.message as any;
               }
               else {
                currentQREncoded = response.data;
               }
            }
          })
          .catch( (e) => {
             console.log(e);           
             currentAlertOpen = true;
             currentAlertText= e;
          })
          .finally( () => {
              this.printQRLoading = false;           
              this.printQREncoded = currentQREncoded;
              this.isLoading = false;       
              this.alertOpen = currentAlertOpen;
              this.alertText = currentAlertText;
          });
      },
      async fetchPrefixes() {      
        let currentPrefixes = [] as any;        
        await this.axios.post(
          process.env.VUE_APP_API_URL + '/zenship/prefixes',
          { 
            
          })
          .then(function(response) {         
            currentPrefixes = response.data.result;
          })
          .catch( (e) => {
            console.log(e);
          });
         this.prefixes = currentPrefixes;  
    },
      async fetchShipItemMetrics() {
          //if(this.shipItemMetricRefreshedAt > Date.now() === true) return;
          this.isLoadingShipItemMetrics = true;
          let currentLoading = true;
          let currentMetric = {};          
          await this.axios.post(
            process.env.VUE_APP_API_URL + '/zenship/metrics',
            { 
              distribution_center_id : this.distribution_center_id,
              view: this.shipItemMetricsView
            })
            .then(function(response) {         
               currentMetric = response.data;
               currentLoading = false;
            })
            .catch( (e) => {
               console.log(e);
               currentLoading = false;
            });
         this.isLoadingShipItemMetrics = currentLoading;  
         this.shipItemMetrics = currentMetric;
         this.shipItemMetricRefreshedAt = Date.now() + ( 300 * 1000 );
      },
      async fetchFulfillmentMetrics() {
        this.isLoadingFFMetrics = true;
        let currentLoading = true;
        let currentMetric = {};          
        await this.axios.post(
          process.env.VUE_APP_API_URL + '/zenship/ff-metrics',
          { 
            distribution_center_id : this.distribution_center_id,
            view: this.FFMetricsView
          })
          .then(function(response) {         
             currentMetric = response.data;
             currentLoading = false;
          })
          .catch( (e) => {
             console.log(e);
             currentLoading = false;
          });
       this.isLoadingFFMetrics = currentLoading;  
       this.FFMetrics = currentMetric;
       this.FFMetricRefreshedAt = Date.now() + ( 300 * 1000 );
    },
      clearScanCode(key : string) {        
        if(key === 'upc' && this.isFieldLocked('upc') === false){
          this.upcCode = "";
        }         
        else if(key === 'sku' && this.isFieldLocked('sku') === false) {
         this.skuCode = "";
        }
        else if(key === 'location' && this.isFieldLocked('location') === false) {
         this.locationCode = "";
        }    
        else if(key === 'qr' && this.isFieldLocked('qr') === false){
          this.qrCode = "";
        } 
        else if(key === 'prefix' && this.isFieldLocked('prefix') === false){
          this.prefix = "";
        }    
     },
     setApp(app : any) {
       if(this.currentApp === null) {
         this.currentApp = app;
       }
     },
     clearAllScanCode() {
       //console.log('clear all scan code');
       this.clearScanCode('upc'); 
       this.clearScanCode('sku');
       this.clearScanCode('location');
       this.clearScanCode('qr');
       this.clearScanCode('prefix');       
       this.scanCode = "";
     },
     clearFocus() {
      window.setTimeout(function() {        
        (document.activeElement as any).blur();
       },250);
     },
     async logOut() {
      await this.axios.post(
        process.env.VUE_APP_API_URL + '/zenship/logout?' + (this.debugAPI ? 'XDEBUG_SESSION_START=true' : ''), {})
        .catch( (e) => {
           console.log(e);                   
        })
        .finally( () => {
          this.user = {
            authenticated : false,
            name : '',
            token : '',
            distribution_centers : []
          }
          this.distribution_center_id = 0;
          window.location.href='/login';             
        });
      },      
      addScannerEventHandler() {
        //console.log('i added scanner event handler');  
        //add only once
        document.addEventListener('keypress',this.handleScannerEvent);        
      },
      findResource(name : any)  : Resource | boolean {
        if(this.currentApp.resourceCollection[name]) {
          return this.currentApp.resourceCollection[name];
        }
        return false;
      },
      getUserDistributionCenter() : string {
        if(this.user.distribution_centers && this.user.distribution_centers.length > 0) {
          return (this.user.distribution_centers[0] as any).name;
        }
        return "Shipments";
      },
      handleScanCodeInput(e : any, key : string) {
        let keyValue = e.target.value;
        if(this.noTrailingSpace && keyValue.length > 0 && keyValue.indexOf(' ') >= 0) {
          keyValue = keyValue.substring(0,keyValue.indexOf(' '));
        }
        if(this.noTrailingSpace && keyValue.length > 0 && keyValue.indexOf('%') >= 0) {
          keyValue = keyValue.substring(0,keyValue.indexOf('%'));
        }
        if(key === 'upc'){
          this.upcCode = keyValue;
        }         
        else if(key === 'sku') {
         this.skuCode = keyValue;
        } 
        else if(key === 'location') {
         this.locationCode = keyValue;
        } 
        else if(key === 'qr') {
          this.qrCode = keyValue;
         }
         else if(key === 'prefix') {
          this.prefix = keyValue;
         }     
         else if(key === 'status_list') {
          this.status_list = keyValue;
         }          
      },
      handleScannerEvent(e: any) {
        //handle scan event only if enable scan is on
        if(this.enableScan === false) return;
        if(e.target.type ==='text') return;
        if(e.target.type ==='search') return;        
        if(e.key === "Enter") {       
          //console.log('i pressed enter');        
          this.onEnterScanCode(this.scanCode);
          this.invokeHandler();
          this.scanCode = "";
        }
        else {
         this.scanCode += e.key;
        }
      },
      handleAlertClose() {
        if(this.alertCloseHandler != null && typeof this.alertCloseHandler ==='function') {
            (this.alertCloseHandler as any)();
        }
        else {
         this.closeAlert();
        }
      },
      invokeHandler() {
         if(this.currentRoute.length > 0) {
             const eventHandler = this.getActionHandler(this.currentRoute);
             if(eventHandler === false) return;
             //console.log('i invoke');
             eventHandler.handler();
         }
      },
      onEnterScanCode(code : string) {
         if(this.noTrailingSpace && code.length > 0 && code.indexOf(' ') >= 0) {
           code = code.substring(0,code.indexOf(' '));
         }
         if(this.noTrailingSpace && code.length > 0 && code.indexOf('%') >= 0) {
          code = code.substring(0,code.indexOf('%'));
         }
         if(this.enableDepoFormat && code.length > 3) {
          if(code.indexOf('SPIN') <0 && code.indexOf("LOC") < 0) {
            code = code.substring(3,code.length);
          }
         }
         if(code.indexOf('LOC') === 0) {
           if(this.isFieldLocked('location') === false) {
            this.locationCode = code;
           }
         }
         else if(code.indexOf('SPIN') === 0) {
          this.qrCode = code;
         }
         else {
          //console.log(`I changed upc code to ${code}`);
          if(this.currentRoute !== 'identify' && this.currentRoute != 'register') return;
          if(this.isFieldLocked('upc') === false && this.enableUPC) {
            this.upcCode = code;
          }
          if(this.isFieldLocked('sku') === false && this.enableUPC === false) {
            this.skuCode = code;
          }         
         }
      },
      setScanner(val = true) {
         this.enableScan = val;
         if(val === false) this.clearAllScanCode();
         //console.log(`the scanner is now ` + val as string);
      },
      setWildCard(val = false) {
        this.enableWildCard = val;
      },
      setNoTrailingSpace(val = true) {
         this.noTrailingSpace = val;
      },
      setEnableUPC(val = true) {
        this.upcCode = "";
        this.removeLockedField('upc');
        this.enableUPC = val;
      },
      setEnableLogging(val = true) {
        this.console = [];
        this.enableLogging = val;
      },
      setEnableDepoFormat(val = true) {
        this.enableDepoFormat = val;
      },
      removeScannerEventHandler() {
        //console.log('i remove scanner');
        document.removeEventListener('keypress',this.handleScannerEvent); 
      },
      setActionResponse(key: any, requestId: any, val : ActionResponse ) {
        if(requestId === null) console.log('request id is null');
        type ObjectKey = keyof typeof this.$state;
        const myVar = key as ObjectKey;
        const responseArray = (this[myVar] as ActionResponse[]);    
        const foundIndex = responseArray.findIndex(function(element,index, arr) {
            return (requestId === arr[index].requestId);
        });
        if(foundIndex < 0) {
          return;
        }        
        (this[myVar] as ActionResponse[])[foundIndex] = val;        
      },
      getNewActionResponse(key: any) {
        type ObjectKey = keyof typeof this.$state;
        const myVar = key as ObjectKey;  
        const response: ActionResponse = {
            isLoading: true,
            requestId: uuidv4()
        };        
        (this[myVar] as ActionResponse[]).push(response);
        return response;
      },
      clearActionResponse(key: any) {
        type ObjectKey = keyof typeof this.$state;
        const myVar = key as ObjectKey;        
        (this[myVar] as ActionResponse[]) = [];
      },
      setAlert(open : boolean, text = "", title = 'Exception', onClose: any =  null) {         
        this.alertOpen = open;        
        this.alertText = text;
        this.alertTitle = title;
        if(onClose !== null) {
          this.alertCloseHandler = onClose;
        }
      },
      closeAlert() {
        this.alertOpen = false;
        this.alertText = "";
        this.alertCloseHandler = null;
        this.alertCloseText = 'Cancel';
      },     
      setLoading(val: boolean, message = '') {
        if(message && message.length > 0 && val === true) {
            this.loadingText = message;
        }
        if(val === false) {
          this.loadingText = "Please wait ...";
        }
        this.isLoading = val;
      },
      setAxiosConfig() {
        if(this.user.token.length > 0) {
          this.axios.defaults.headers.common['Authorization'] = "Bearer " + this.user.token;
        }
      },
      setCurrentRoute(val : any) {
        this.currentRoute = val;
        //console.log(this.currentRoute);
      },
      setEnvironment(val: any) {
        this.environment = val;
      },
      setCurrentResource(val: any) {
        if(this.currentResource) {
          this.currentResource.unMountResource();
        }
        this.currentResource = val;
        this.currentResource.mountResource();
      },
      setToken(val: any) {
        this.user.token = val.token;
        this.user.authenticated = true;
        this.user.name = val.name;
        this.user.distribution_centers = val.distribution_centers;
        this.distribution_center_id = val.distribution_centers[0].id;
        this.setAxiosConfig();
      },
      removeActionHandler(key: string) {
        const index = this.getActionHandlerIndex(key);
        if(index >=0) {
          this.actionHandlers.splice(index, 1);
        }
      },
      clearAllLockedFields() {
        this.lockedFields = [];
      },
      addLockedField(key: string) {      
        if(this.hasLockedField(key) === true) return;  
        this.lockedFields.push(key as any);
      },
      hasLockedField(key: string) {        
        if(this.lockedFields.length ==0) return false;
        const index = this.lockedFields.findIndex(function(element) {
          return element === key;
         });
        if(index >=0) {
          return true;
        }
        return false;
      },
      isFieldLocked(key: string) {
        return this.hasLockedField(key);
      },
      removeLockedField(key: string) {
         if(this.lockedFields.length ==0) return;
         const index = this.lockedFields.findIndex(function(element) {
          return element === key;
         });
         if(index >=0) {
          this.lockedFields.splice(index, 1);
         }
      },
      setActionHandler(key: string, val: any) {
         const eventHandler = { name: key, handler: val } as ActionHandler;
         this.actionHandlers.push(eventHandler);
         //console.log(this.actionHandlers);
      },
      getActionHandlerIndex(key: string) {
        return this.actionHandlers.findIndex(function(element,index, arr) {
          return (key === arr[index].name);
        });
      },
      getActionHandler(key: string) {
        if(this.hasActionHandler(key) === false) return false;
        const index = this.actionHandlers.findIndex(function(element,index, arr) {
          return (key === arr[index].name);
        });
        return this.actionHandlers[index];
      },
      hasActionHandler(key: string) {
          return (this.getActionHandlerIndex(key) >= 0 ? true : false)
      },
      async presentConfirmation(text: string, okHandler: any) {
        const alert = await alertController.create({
          header: text,
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {                
                 //console.log('i clicked cancel');
              },
            },
            {
              text: 'YES',
              role: 'confirm',
              handler: () => {
                if(okHandler) {
                  okHandler();
                 }
                //console.log('i clicked confirm');
              },
            },
          ],
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
      },
      async presentActionSheet(actions : (ActionSheetButton<any>)[]) {
        const actionSheet = await actionSheetController.create({
          header: 'Please select an action below.',
          buttons: actions
        });
        await actionSheet.present();
        const res = await actionSheet.onDidDismiss();
      },
      async openModal(aModal: any) {
        this.currentModal = aModal;
        const modal = await modalController.create({
          component: CustomModal          
        });
        modal.present();
      },
      async closeModal() {
        await modalController.dismiss(null, 'cancel');
        this.currentModal = null;
      }
    },
})

function getAxios() : AxiosInstance{
  const newAxios = axios.create({timeout: 3000});
  newAxios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const redirectStatus = [401,403];
    if(error.response && redirectStatus.indexOf(error.response.status) >= 0) {
       window.location.href="/login";
    }
    else if(error.message) {
      if(console) console.log(error);
      if(error.code === 'ERR_NETWORK') {
        window.location.href="/login?error=network";
      }
    }
    return Promise.reject(error);
  });
  return newAxios;  
}