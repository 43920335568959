
import { kProvider, kDialog, kDialogButton } from 'konsta/vue';
import { IonApp, IonRouterOutlet,IonLoading } from '@ionic/vue';
import { defineComponent } from 'vue';
import './App.css';
import { useZenShipStore } from '@/stores/zenship';
import { storeToRefs } from 'pinia'


export default defineComponent({
  name: 'App',
  setup() {
        const store = useZenShipStore();     
        const { getLoading, getLoadingText, getAlertText, getAlertOpen, getAlertTitle } = storeToRefs(store)   
        return {
          store,
          getLoading,
          getLoadingText,
          getAlertText,
          getAlertTitle,
          getAlertOpen,
        }
  },
  components: {
    IonApp,
    IonRouterOutlet,
    kProvider,
    IonLoading,
    kDialog,
    kDialogButton
  },
  mounted() {
      this.store.init(process.env.VUE_APP_ENV);      
  },
  unmounted() {
    this.store.destroy();
  },
  
});
