
import {
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    IonListHeader,
    IonBadge,
    IonIcon,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { checkmarkCircleOutline, closeCircleOutline, stopCircleOutline, bookmark } from 'ionicons/icons';

export default defineComponent({
    name: 'ZenField',      
    components: { 
                IonItem, 
                IonLabel,
                IonInput,
                IonTextarea,
                IonListHeader,                
                IonBadge,
                IonIcon,                
                },
    props: ['field'],
    methods: {
        getIcon(icon = ["default","primary"]) {              
            switch(icon[0]) {
                case 'checkmarkCircleOutline': 
                return checkmarkCircleOutline;
                case 'closeCircleOutline':
                return closeCircleOutline;  
                case 'stopCircleOutline':
                return stopCircleOutline;
                case 'bookmark':
                    return bookmark;    
                case 'default':
                return '';  
            }
            return '';
        },
        getIconColor(icon = ["default","primary"]) {                 
            return icon[1];
        },
    },    
    setup() {
    /*
    const store = useZenShipStore();
    
    return {             
        store,          
    };
    */
    },
});
